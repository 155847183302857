import baseAxios from './baseAxios';
import Country from '../interfaces/Reference/Country';
import { Product } from '../interfaces/Store/Product';
import Ethnicity from '../interfaces/Store/Ethnicity';
import { Configuration } from '../interfaces/Store/Configuration';
import { State } from "../interfaces/Reference/State";
import { BloodMarkerNew, BloodMarkerUnit } from '../interfaces/Blood/BloodMarker';
import { ContraceptivesType, HormoneTherapyType } from '../interfaces/Data/responceDataTypes';
import { MarkerCategory } from "../interfaces/Blood/MarkerCategory";
import { PhysioMarker } from "../interfaces/Blood/PhysioMarker";

export default {
  getStates: (): Promise<State[]> => baseAxios.get('/api/pages/references/all-states')
    .then((responseData) => responseData.data.states),

  getCountries: (): Promise<Country[]> => baseAxios.get('/api/pages/references/countries')
    .then((responseData) => (responseData.data.countries as Country[]).sort((a, b) => a.name?.localeCompare(b.name))),

  getProducts: (): Promise<Product[]> => baseAxios.get('/api/pages/references/products')
    .then((responseData) => responseData.data.products),

  getEthnicities: (): Promise<Ethnicity[]> => baseAxios.get('/api/pages/references/ethnicities')
    .then((responseData) => responseData.data.ethnicities),

  getConfiguration: (): Promise<Configuration> => baseAxios.get('/api/pages/references/configuration')
    .then((responseData) => responseData.data.parameters),

  getBloodMarkers: (): Promise<Omit<BloodMarkerNew, 'units'>[]> => baseAxios.get('/api/public/references/blood-markers')
    .then((responseData) => responseData.data.items),

  getBloodMarkerUnits: (): Promise<Omit<BloodMarkerUnit, 'id'>[]> => baseAxios.get('/api/public/references/blood-marker-units')
    .then((responseData) => responseData.data.items),

  getMarkerCategories: (): Promise<MarkerCategory[]> => baseAxios.get('/api/public/references/marker-categories')
    .then((responseData) => responseData.data.items),

  getPhysioMarkers: (): Promise<PhysioMarker[]> => baseAxios.get('/api/public/references/physio-markers')
    .then((responseData) => responseData.data.items),

  getContraceptionTypes: (): Promise<ContraceptivesType[]> => baseAxios.get('/api/public/references/contraception-types')
    .then((responseData) => responseData.data),

  getHormoneTherapyTypes: (): Promise<HormoneTherapyType[]> => baseAxios.get('/api/pages/references/hormone-therapy')
    .then((responseData) => responseData.data),

  getReproductiveStages: (): Promise<ContraceptivesType[]> => baseAxios.get('/api/pages/references/reproductive-stages')
    .then((responseData) => responseData.data),
};
