import { createAsyncThunk } from '@reduxjs/toolkit';
import askItApi from "../../../services/Customer/askItApi";

export const fetchAskAISessions = createAsyncThunk(
  'user/fetchAskAISessions',
  async () => await askItApi.getChatSessions(),
);

export default {
  fetchAskAISessions,
};
