import { Builder } from "@builder.io/react";

import themeHowItWorks from "./themeHowItWorks";

Builder.registerComponent(themeHowItWorks, {
  name: "Theme How It Works Section",
  inputs: [
    {
      type: "text",
      name: "sectionTitle",
      friendlyName: "Section Title",
      defaultValue: "Here’s how InsideTracker works",
    },
    {
      type: "object",
      name: "sectionBtn",
      friendlyName: "Section Button",
      defaultValue: {
        sectionBtnLabel: "Take the tour",
        sectionBtnUrl: "https://www.insidetracker.com",
      },
      subFields: [
        {
          name: "label",
          type: "string",
          friendlyName: "Button Label",
          defaultValue: "Take the tour",
        },
        {
          name: "url",
          type: "url",
          friendlyName: "Button Link",
          defaultValue: "https://www.insidetracker.com",
        },
        {
          name: "isInternal",
          type: "boolean",
          friendlyName: "Check if the Link is Internal",
          defaultValue: false,
        },
        {
          name: "pageName",
          type: "string",
          friendlyName: "Enter Page Name for Internal Link",
          helperText: "Only Applicable when the link is internal"
        }
      ]
    },
    {
      type: "list",
      name: "steps",
      friendlyName: "Steps",
      subFields: [
        {
          name: "id",
          type: "number",
          required: true,
          friendlyName: "Step Number"
        },
        {
            name: "title",
            type: "string",
            required: true,
            friendlyName: "Step Title"
        },
        {
            name: "info",
            type: "longText",
            required: true,
            friendlyName: "Step Info"
        },
        {
          name: "icon",
          type: "file",
          friendlyName: "Step Icon",
          allowedFileTypes: ['png','svg']
        },
      ]
    }
  ]
})