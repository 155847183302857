import React, { useLayoutEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Builder } from "@builder.io/react";

import styles from "./themeHowItWorks.module.less";

gsap.registerPlugin(ScrollTrigger);

export default function HowItWorks({
  steps,
  sectionTitle,
  sectionBtn,
  attributes,
}) {
  const stepsRef = useRef(null);
  useLayoutEffect(() => {
    if (!Builder.isEditing) {
      const mm = gsap.matchMedia();
      const stepctx = gsap.context(() => {
        ScrollTrigger.defaults({
          markers: false,
        });
        // Mobile-specific animations
        mm.add("(max-width: 768px)", () => {
          const steps = gsap.utils.toArray(stepsRef.current.querySelectorAll(".work-step"));

          gsap.set(".insidetracker-works", { marginBottom: 0 });

          steps.forEach((step) => {
            ScrollTrigger.create({
              trigger: step,
              start: "top center",
              end: "bottom center",
              scrub: true,
              onEnter: () => gsap.to(step, { opacity: 1, duration: 0.5 }),
              onLeave: () => gsap.to(step, { opacity: 0.4, duration: 0.5 }),
              onEnterBack: () => gsap.to(step, { opacity: 1, duration: 0.5 }),
              onLeaveBack: () => gsap.to(step, { opacity: 0.4, duration: 0.5 }),
            });
          });
        });

        // Desktop-specific animations
        mm.add("(min-width: 769px)", () => {
          const steps = gsap.utils.toArray(stepsRef.current.querySelectorAll(".work-step"));

          const stepsTl = gsap.timeline({
            duration: 4,
            scrollTrigger: {
              trigger: ".insidetracker-works",
              start: "top 70px",
              end: "+=400%",
              scrub: 2,
              pin: true,
            },
          });

          steps.forEach((step, index) => {
            const stepDuration = 1; // Duration for each step animation
            stepsTl.to(step, { scale: 1.1, opacity: 1, duration: stepDuration }, index)
              .to(step, { scale: 1, opacity: 0.4, duration: stepDuration }, index + 0.5);
          });
        });
      });

      return () => {
        mm.revert(); // Cleanup GSAP matchMedia
        stepctx.revert();
      };
    }
  }, []);

  if (steps.length > 0) {
    return (
      <section className={`${styles.insidetrackerWorks} insidetracker-works ${!Builder.isEditing ? `${styles.stepsHeight}` : ""}`}>
        <div className={`${styles.insidetrackerWorksContainer}`}>
          <div className={`${styles.insidetrackerWorksWrap}`}>
            <h2 className={`${styles.sectionTitle}`}>{sectionTitle}</h2>
            <div className={`${styles.workStepsWrap}`}>
              <div className={`${styles.workSteps}`} ref={stepsRef}>
                {steps.map((step) => (
                  <div key={step.id} className={`${styles.workStep} work-step`}>
                    <div className={`${styles.image}`}>
                      <img src={step.icon} alt={step.title} />
                    </div>
                    <h4 className={`${styles.subTitle}`}>Step {step.id}</h4>
                    <h3 className={`${styles.title}`}>{step.title}</h3>
                    <p className={`${styles.content}`}>{step.info}</p>
                  </div>
                ))}
              </div>
              {
                sectionBtn.isInternal
                  ? <Link to={sectionBtn.pageName} className={`${styles.stepsBtn}`}>{sectionBtn.label}</Link>
                  : <a href={sectionBtn.url} className={`${styles.stepsBtn}`}>{sectionBtn.label}</a>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <div {...attributes} style={{ color: "#fff", fontSize: "30px", textAlign: "center" }}>Please Insert the Scrolling Steps</div>
  );
}
