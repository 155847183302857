import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

export const usStatesSelector = ({ reference }: RootState) => reference.usStates;
export const canadaStatesSelector = ({ reference }: RootState) => reference.canadaStates;
export const statesSelector = createSelector(usStatesSelector, canadaStatesSelector, (usStates, canadaStates) => usStates.concat(canadaStates));
export const countriesSelector = ({ reference }: RootState) => reference.countries;
export const ethnicitiesSelector = ({ reference }: RootState) => reference.ethnicities;
export const bloodMarkersSelector = ({ reference }: RootState) => reference.bloodMarkers;
export const bloodMarkerCategoriesSelector = ({ reference }: RootState) => reference.bloodMarkerCategories;

export const physioMarkersSelector = ({ reference }: RootState) => reference.physioMarkers;
export const genderIdentitiesSelector = ({ reference }: RootState) => reference.genderIdentities;
export const contraceptionTypesSelector = ({ reference }: RootState) => reference.contraceptionTypes;
export const reproductiveStagesSelector = ({ reference }: RootState) => reference.reproductiveStages;
export const hormoneTherapyTypesSelector = ({ reference }: RootState) => reference.hormoneTherapyTypes;

export const physioMarkerByAbbrSelector = (abbr: string) => ({ reference }: RootState) => reference.physioMarkers
  .filter((item) => item.abbr === abbr && item.display.inRelation === true)[0];
export const physioResultsSelector = ({ reference }: RootState) => reference.physioResults;

export const lastAvailablePhysioMarkerValueSelector = (physioMarkerAbbr: string) => ({ reference }: RootState) => {
  const physioMarkerResults = reference.physioResults;
  // eslint-disable-next-line no-restricted-syntax
  for (const physioMarkerResult of physioMarkerResults) {
    // eslint-disable-next-line no-restricted-syntax
    for (const markerValue of physioMarkerResult.markerValues) {
      if (markerValue.markerAbbr === physioMarkerAbbr) {
        return markerValue;
      }
    }
  }

  return null;
};

export default {
  statesSelector,
  usStatesSelector,
  canadaStatesSelector,
  countriesSelector,
  ethnicitiesSelector,
  bloodMarkerCategoriesSelector,
  physioMarkersSelector,
  physioResultsSelector,
};
