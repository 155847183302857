import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';

import styles from "./themeHealthSpanSection.module.less";

export default function HealthSpanSection({
  categories,
  sectionTitle,
  sectionText,
  sectionBtn,
  attributes,
}) {
  const [activeItem, setActiveItem] = useState(null); // Active item for mobile
  const [tooltip, setTooltip] = useState({ visible: false, text: "", id: 1 }); // Tooltip for desktop
  const [isMobile, setIsMobile] = useState(false);

  // Update mobile/desktop state on resize
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setActiveItem({ ...categories[0], className: `item-1` });
      setIsMobile(true);
    }
  }, []);

  // Set active item for mobile
  const handleSetActiveItem = (item, index) => {
    setActiveItem({ ...item, className: `item-${index + 1}` });
  };

  // Handle mouse enter for desktop
  const handleMouseEnter = (item) => {
    if (!isMobile) {
      setTooltip({
        visible: true,
        text: item.info,
        id: item.id,
      });
    }
  };

  // Handle mouse leave for desktop
  const handleMouseLeave = () => {
    if (!isMobile) {
      setTooltip({ ...tooltip, visible: false });
    }
  };
  if ( categories.length > 0 ) {
    return (
      <section className={`${styles.health360}`}>
        <div className={`${styles.health360Container}`}>
          <div className={`${styles.health360Wrapper}`}>
            <div className={`${styles.health360ItemsWrap}`}>
              <div className={`${styles.health360Items}`}>
                {categories.map((item, index) => (
                  <div
                    key={item.id}
                    className={`${styles.health360Item} health360Item ${
                      isMobile && activeItem?.id === item.id
                        ? activeItem.className
                        : ""
                    } ${activeItem?.id === item.id ? `${styles.active}` : ""}`}
                    style={{ "--index": index }}
                    onClick={() => isMobile && handleSetActiveItem(item, index)}
                    onMouseEnter={() => handleMouseEnter(item)}
                    onMouseLeave={handleMouseLeave}
                    role="none"
                  >
                    <div className={`${styles.health360ItemIcon}`}>
                      <img
                        src={item.icon}
                        alt={item.title}
                        className={`${styles.noHover}`}
                      />
                      <img
                        src={item.icon_hover}
                        alt={`${item.title} hover`}
                        className={`${styles.onHover}`}
                      />
                    </div>
                    <div className={`${styles.health360ItemContent}`}>
                      <p className={`${styles.title}`}>{item.title}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Tooltip for Desktop */}
              {!isMobile && tooltip.visible && (
                <div className={`${styles.health360Tooltips}`}>
                  <div
                    className={`${styles.tooltip} ${styles.visible} tooltip visible item-${tooltip.id}`}
                    style={{
                      position: "absolute",
                    }}
                  >
                    <p>{tooltip.text}</p>
                  </div>
                </div>
              )}

              {/* Tooltip for Mobile */}
              {isMobile && activeItem && (
                <div className={`${styles.health360Tooltips} health-360-tooltips ${activeItem.className}`}>
                  <p className={`${styles.tooltipTitle}`}>
                    {activeItem.title}
                    :
                  </p>
                  <p>{activeItem.info}</p>
                  <div className={`${styles.tooltipArrow} tooltip-arrow`} />
                </div>
              )}
            </div>

            {/* Static Content */}
            <div className={`${styles.health360Content} ${!isMobile && tooltip.visible ? `${styles.health360TooltipActive}` : ""} ${isMobile ? `${styles.mobileHidden}` : ""}`}>
              <h2 className={`${styles.health360Title}`}>{sectionTitle}</h2>
              <p className={`${styles.health360Desc}`}>{sectionText}</p>
              {
                sectionBtn.isInternal
                  ? <Link to={sectionBtn.pageName} className={`${styles.health360Btn}`}>{sectionBtn.label}</Link>
                  : <a href={sectionBtn.url} className={`${styles.health360Btn}`}>{sectionBtn.label}</a>
              }
            </div>
          </div>

          <div className={`${styles.health360Content} ${!isMobile ? `${styles.desktopHidden}` : ""}`}>
            <h2 className={`${styles.health360Title}`}>{sectionTitle}</h2>
            <p className={`${styles.health360Desc}`}>{sectionText}</p>
            {
              sectionBtn.isInternal
                ? <Link to={sectionBtn.pageName} className={`${styles.health360Btn}`}>{sectionBtn.label}</Link>
                : <a href={sectionBtn.url} className={`${styles.health360Btn}`}>{sectionBtn.label}</a>
            }
          </div>
        </div>
      </section>
    );
  }
  return (
    <div {...attributes} style={{ color: "#fff", fontSize: "30px", textAlign: "center" }}>Please Add All the Categories</div>
  );
}
