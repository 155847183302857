/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { AskAIState } from './types';
import { fetchAskAISessions } from './actions';

export const initialAskAIState: AskAIState = {
  sessions: null,
};

const askAISlice = createSlice({
  name: 'askAI',
  initialState: initialAskAIState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAskAISessions.fulfilled, ((state, action) => {
      state.sessions = action.payload;
    }));
  },
});

export default askAISlice.reducer;
