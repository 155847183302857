import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialStateType } from './types';
import { fetchAppInitialData } from '../app/actions';

const initialState: InitialStateType = {
  configuration: null,
  restrictedPopupOpen: false,
  intBannerShown: false,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    makeRestrictedPopup: ((state, action:PayloadAction<boolean>) => {
      state.restrictedPopupOpen = action.payload;
    }),
    setIntBannerShown: ((state) => {
      state.intBannerShown = true;
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppInitialData.fulfilled, ((state, action) => {
      state.configuration = action.payload.configuration;
    }));
  },
});
export const { makeRestrictedPopup, setIntBannerShown } = commonSlice.actions;
export default commonSlice.reducer;
