import { createBaseAxios } from '../baseAxios';
import CustomerAuthTokenProvider from './customerAuthTokenProvider';
import { fetchJwtToken } from "./userAuthorizedAxios";
import config from '../../config';

const myPlanAuthorizedAxios = createBaseAxios();

myPlanAuthorizedAxios.defaults.baseURL = config.isProduction ? 'https://ask.insidetracker.com' : 'https://ask.segterra.com';
myPlanAuthorizedAxios.interceptors.request.use(async (config) => {
  let token = CustomerAuthTokenProvider.get();
  const expiresIn = CustomerAuthTokenProvider.getSecondsToExpiration();

  if (expiresIn < 0) {
    await fetchJwtToken();
    token = CustomerAuthTokenProvider.get();
  } else if (expiresIn < 300) {
    // fetch new token 5 mins before expiration
    fetchJwtToken();
  }

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    // config.headers.Authorization = `Bearer 96827`;
  }

  return config;
});

export default myPlanAuthorizedAxios;
