import baseAxios from './baseAxios';

export type UserLocation = {
  ip: string,
  country: string,
  countryCode: string,
  city: string,
};

export default {
  getLocationData: (): Promise<UserLocation> => baseAxios.get('/api/pages/publicPage/userLocation', { timeout: 5000 })
    .then((response) => response.data),
};
