import { createAsyncThunk } from '@reduxjs/toolkit';
import referenceApi from '../../services/referenceApi';
import genderIdentityApi from '../../services/Customer/genderIdentityApi';
import physioDataApi from "../../services/Customer/physioDataApi";

export const fetchBloodData = createAsyncThunk(
  'references/fetchBloodData',
  async () => {
    const [bloodMarkers, units, bloodMarkerCategories] = await Promise.all([
      referenceApi.getBloodMarkers(),
      referenceApi.getBloodMarkerUnits(),
      referenceApi.getMarkerCategories(),
    ]);

    return {
      bloodMarkers, units, bloodMarkerCategories,
    };
  },
);

export const fetchPhysioData = createAsyncThunk(
  'references/fetchPhysioData',
  async () => {
    const [physioMarkers] = await Promise.all([
      referenceApi.getPhysioMarkers(),
    ]);

    return { physioMarkers };
  },
);

export const fetchPhysioResults = createAsyncThunk(
  'references/fetchPhysioResults',
  async () => {
    const [physioResults] = await Promise.all([
      physioDataApi.getPhysioResults(),
    ]);

    const sortedResults = physioResults.reverse();

    return { physioResults: sortedResults };
  },
);

export const fetchGenderIdentities = createAsyncThunk(
  'app/fetchGenderIdentities',
  async () => await genderIdentityApi.getGenderIdentityReferences(),
);

export const fetchContraceptionTypes = createAsyncThunk(
  'app/fetchContraceptionTypes',
  async () => await referenceApi.getContraceptionTypes(),
);

export const fetchHormoneTherapyTypes = createAsyncThunk(
  'app/fetchHormoneTherapyTypes',
  async () => await referenceApi.getHormoneTherapyTypes(),
);

export const fetchReproductiveStages = createAsyncThunk(
  'app/fetchReproductiveStages',
  async () => await referenceApi.getReproductiveStages(),
);
