import { createSlice } from '@reduxjs/toolkit';
import { InitialStateType } from './types';
import { fetchAppInitialData } from '../app/actions';
import {
  fetchGenderIdentities,
  fetchBloodData,
  fetchPhysioData,
  fetchPhysioResults,
  fetchContraceptionTypes,
  fetchReproductiveStages,
  fetchHormoneTherapyTypes,
} from './actions';

const initialState: InitialStateType = {
  canadaStates: null,
  usStates: null,
  countries: null,
  ethnicities: null,
  bloodMarkers: null,
  bloodMarkerCategories: null,
  physioMarkers: null,
  physioResults: null,
  genderIdentities: null,
  contraceptionTypes: null,
  reproductiveStages: null,
  hormoneTherapyTypes: null,
};

export const referenceSlice = createSlice({
  name: 'reference',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppInitialData.fulfilled, ((state, {
      payload: {
        countries, states, ethnicities,
      },
    }) => {
      state.countries = countries;
      state.usStates = states.filter((state) => state.countryAbbr === 'US');
      state.canadaStates = states.filter((state) => state.countryAbbr === 'CA');
      state.ethnicities = ethnicities;
    }));
    builder.addCase(fetchBloodData.fulfilled, (state, action) => {
      state.bloodMarkers = action.payload.bloodMarkers.map((marker) => ({
        ...marker,
        units: action.payload.units
          .filter((unit) => unit.markerAbbr === marker.abbr)
          .sort((a) => (a.name === marker.unit ? -1 : 0)) // put main unit first
          .map((unit, idx) => ({ ...unit, id: idx })),
      }));
      state.bloodMarkerCategories = action.payload.bloodMarkerCategories;
    });

    builder.addCase(fetchPhysioData.fulfilled, (state, action) => {
      state.physioMarkers = action.payload.physioMarkers;
    });

    builder.addCase(fetchPhysioResults.fulfilled, (state, action) => {
      state.physioResults = action.payload.physioResults;
    });

    builder.addCase(fetchGenderIdentities.fulfilled, ((state, {
      payload: {
        identities,
      },
    }) => {
      state.genderIdentities = identities;
    }));

    builder.addCase(fetchContraceptionTypes.fulfilled, (state, action) => {
      state.contraceptionTypes = action.payload;
    });

    builder.addCase(fetchHormoneTherapyTypes.fulfilled, (state, action) => {
      state.hormoneTherapyTypes = action.payload;
    });

    builder.addCase(fetchReproductiveStages.fulfilled, (state, action) => {
      state.reproductiveStages = action.payload;
    });
  },
});

export default referenceSlice.reducer;
