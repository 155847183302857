import { Builder } from "@builder.io/react";

import themeHealthSpanSection from "./themeHealthSpanSection";

Builder.registerComponent(themeHealthSpanSection, {
  name: "Theme Health Span Section",
  inputs: [
    {
      type: "text",
      name: "sectionTitle",
      friendlyName: "Section Title",
      defaultValue: "A 360 view of your health",
    },
    {
      type: "longText",
      name: "sectionText",
      friendlyName: "Section Text",
      defaultValue: "We organize your data into 10 'healthspan categories.' For each, you'll get a score, 0 to 100. That lets you quickly see where your health needs work—and where to put your effort.",
    },
    {
      type: "object",
      name: "sectionBtn",
      friendlyName: "Section Button",
      defaultValue: {
        sectionBtnLabel: "Explore the 10 categories",
        sectionBtnUrl: "https://www.insidetracker.com",
      },
      subFields: [
        {
          name: "label",
          type: "string",
          friendlyName: "Button Label",
          defaultValue: "Explore the 10 categories",
        },
        {
          name: "url",
          type: "url",
          friendlyName: "Button Link",
          defaultValue: "https://www.insidetracker.com",
        },
        {
          name: "isInternal",
          type: "boolean",
          friendlyName: "Check if the Link is Internal",
          defaultValue: false,
        },
        {
          name: "pageName",
          type: "string",
          friendlyName: "Enter Page Name for Internal Link",
          helperText: "Only Applicable when the link is internal"
        }
      ]
    },
    {
      type: "list",
      name: "categories",
      friendlyName: "Categories",
      subFields: [
        {
          name: "id",
          type: "number",
          required: true,
          friendlyName: "Category ID"
        },
        {
            name: "title",
            type: "string",
            required: true,
            friendlyName: "Category Title"
        },
        {
            name: "info",
            type: "longText",
            required: true,
            friendlyName: "Category Info"
        },
        {
          name: "icon",
          type: "file",
          friendlyName: "Main Icon",
          allowedFileTypes: ['svg']
        },
        {
          name: "icon_hover",
          type: "file",
          friendlyName: "Hover Icon",
          allowedFileTypes: ['svg']
        },
      ]
    }
  ]
})